import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Profissional } from '../dto/users/profissional';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private authService: SocialAuthService
  ) {
    const accessToken = this.cookieService.get('accessToken');
    const refreshToken = this.cookieService.get('refreshToken');
    const user = this.cookieService.get('user');

    if (accessToken && user) {
      this._acessToken = accessToken;
      this._refreshToken = refreshToken;
      this._user = JSON.parse(user);
      this._isAuthenticated.next(true);
    }

    this.authService.authState.subscribe((user) => {
      if (user !== null) {
        this.setAuthenticatedGoogleUser(user);
        this._isAuthenticated.next(true);
      }
    });
  }

  private prefix = 'api/auth/';
  apiUrl = environment.apiUrl;

  private _isAuthenticated = new BehaviorSubject<boolean>(false);
  private _user: (Profissional | SocialUser) & {type: "professional" | "user"} | null = null;
  private _acessToken: string | null = null;
  private _refreshToken: string | null = null;

  login (email: string, password: string) {
    return this.http.post(this.apiUrl + this.prefix + 'professional/login', { email, password }).subscribe((response: any) => {
      this._isAuthenticated.next(true);
      this._acessToken = response.accessToken;
      this._refreshToken = response.refreshToken;
      this._user = response.user;
      this.cookieService.set('accessToken', response.accessToken);
      this.cookieService.set('refreshToken', response.refreshToken);
      this.cookieService.set('user', JSON.stringify(response.user));
    });
  }

  logout () {
      this.authService.signOut();
      this._isAuthenticated.next(false);
      this._acessToken = null;
      this._refreshToken = null;
    this.cookieService.deleteAll();
  }

  isAuthenticated () {
    return this._isAuthenticated.asObservable();
  }

  getToken () {
    return this._acessToken;
  }

  getRefreshToken () {
    return this._refreshToken;
  }

  getUser () {
    return this._user;
  }

  setAuthenticatedGoogleUser(user: SocialUser) {
    this.cookieService.set('user', JSON.stringify(user));
    this.cookieService.set('accessToken', user.idToken);
    this.cookieService.set('refreshToken', user.authToken);
    this._user = {
      ...user,
      type: 'professional'
    };
    this._isAuthenticated.next(true);
  }
}
