import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanMatch, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanMatch{
  constructor(private authService: AuthService) {}

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let authenticated = false;
    this.authService.isAuthenticated().subscribe(isAuthenticated => {
      authenticated = isAuthenticated;
    });

    if (router.url[0].path === 'login' && authenticated) {
      return false;
    }

    return authenticated;
  }

  canMatch(): boolean {
    return !!this.authService.isAuthenticated();
  }
}
