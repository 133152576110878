<header class="header">
  <button class="menu-button" (click)="toggleMenu()">
    @if (menuOpen) {
      <mat-icon>close</mat-icon>
    } @else {
      <mat-icon>menu</mat-icon>
    }
  </button>
  <nav class="nav" [ngClass]="menuOpen ? 'active': ''">
    <img src="./logos/small-logo.svg" alt="Master Geohub logo">
    <ul class="nav-list">
      <li
        class="nav-item"
        *ngFor="let item of navItems"
        [class.active]="isActive(item.link)"
      >
        <a [routerLink]="item.link" class="nav-link">{{ item.label }}</a>
      </li>
    </ul>
    @if (isLoggedIn) {
      <div class="user-actions">
        <div class="user-assets">
          <img src="assets/icons/notificacao.svg" alt="Notificação">
          <img src="assets/icons/shopping-cart.svg" alt="Carrinho">
        </div>

        <div class="user-info">
          <button (click)="handleInteractProfile()" class="button">
            <img src="assets/icons/user.svg" alt="Usuário">
            {{user?.firstName || user?.name}}
            <img src="assets/icons/arrow-down-list.svg" alt="Seta para baixo">
          </button>
          <div *ngIf="isProfileOpen" class="logout" (click)="handleLogout()">
            <img src="assets/icons/log-out.svg" alt="Sair">
            Sair
          </div>
        </div>
      </div>
    } @else {
      <button class="button" routerLink="login">
        <img src="assets/icons/user.svg" alt="Perfil">
        ENTRAR
      </button>
    }
  </nav>
</header>
