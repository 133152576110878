import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CadastroFranqueadoComponent } from './pages/cadastro-franqueado/cadastro-franqueado.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MeusCursosComponent } from './pages/meus-cursos/meus-cursos.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'Home - MasterHub',
  },
  {
    path: 'meus-cursos',
    component: MeusCursosComponent,
    title: 'Meus Cursos',
    canActivate: [AuthGuard]
  },
  {
    title: 'Cadastro',
    path: 'cadastro',
    children: [
      {
        component: CadastroFranqueadoComponent,
        path: 'profissional',
        title: 'Cadastro - Profissional'
      }
    ]
  },
  {
    title: 'Login',
    path: 'login',
    component: LoginComponent,
  }
];
