<main class="login-container">
  <div class="login-modal">
    <h1 class="login-modal__title">Fazer login na sua conta</h1>
    <form (ngSubmit)="onSubmit($event)" [formGroup]="form" class="login-modal__form">
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput autocomplete="email" placeholder="master@hub.com" formControlName="email" type="email">
        <mat-error *ngIf="form.get('email')?.hasError('required')">
          E-mail é obrigatório
        </mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('email')">
          E-mail inválido
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Senha</mat-label>
        <input matInput autocomplete="current-password" placeholder="********" type="password" formControlName="password">
        <mat-error *ngIf="form.get('password')?.hasError('required')">
          Senha é obrigatório
        </mat-error>
        <mat-error *ngIf="form.get('password')?.hasError('minlength')">
          Senha deve ter no mínimo 6 caracteres
        </mat-error>
        <mat-hint align="end"  class="forgot-password">
          <button>Esqueci a senha</button>
        </mat-hint>
      </mat-form-field>
      <button class="form-submit" type="submit">Fazer login</button>
    </form>
    <div class="login-modal__divider">
      <hr>
      <span>ou</span>
      <hr>
    </div>

    <asl-google-signin-button
      shape="rectangular"
      type='standard'
      [width]="400"
      size="medium"
    />

    <p class="login-modal__signup">
      Não tem conta?
      <a href="/cadastro">Cadastre-se</a>
    </p>
  </div>
</main>
