import { GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-login',
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, GoogleSigninButtonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private googleService: SocialAuthService
  ) {}

  ngOnInit() {
    this.googleService.authState.subscribe((user) => {
      if (user !== null) {
        this.authService.setAuthenticatedGoogleUser(user);
        this.router.navigate(['/']);
      }
    });
  }

  form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ])
  }, {
    updateOn: "blur"
  })

  onSubmit(e: SubmitEvent) {
    e.preventDefault();
    if (this.form.valid) {
      try {
        this.authService.login(this.form.value.email!, this.form.value.password!);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
