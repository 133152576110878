<div class="course-container">
  <div class="course-image">
    @if (tag) {
      <div class="course-image__tag">
        <span>{{ tag }}</span>
      </div>
    }
    <img [src]="image" [alt]="'Curso ' + title ">
  </div>
  <div class="course-info-container">
    <div class="course-info-content">
      <p>{{ title }}</p>
      <div>
        @if (hours) {
          <span>{{ hours }}h</span>
        }
        <a [href]="link">
          {{ linkText }}
        </a>
      </div>
    </div>
  </div>
</div>
