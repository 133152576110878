import { SocialAuthService } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-header',
  imports: [MatIconModule, RouterModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router, private gs: SocialAuthService) {
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });

    this.authService.isAuthenticated().subscribe((state) => {
      this.isLoggedIn = state;
    });
    this.user = this.authService.getUser();
    this.navItems = [
      { label: 'Home', link: '/' },
      ...(this.isLoggedIn ?
        [
          { label: 'Meus cursos', link: '/meus-cursos' },
          { label: 'Suporte', link: '/suporte' },
          { label: 'Minhas franquias', link: '/minhas-franquias' }
        ] :
        [
          { label: 'Cursos', link: '/cursos' },
          { label: 'Seja um profissional', link: '/profissional' },
          { label: 'Profissionais certificados', link: '/certificados' },
        ]),
    ];
  }

  ngOnInit() {
    this.gs.authState.subscribe((user) => {
      this.isLoggedIn = user !== null;
      if (user) {
        this.authService.setAuthenticatedGoogleUser(user);
        this.router.navigate(['/']);
      }
    })
  }

  user: any;
  isLoggedIn:boolean = false;
  isProfileOpen = false;
  menuOpen = false;

  navItems: {label: string; link: string;}[] = [];

  currentRoute: string = '';

  isActive(route: string): boolean {
    return this.currentRoute === route;
  }

  handleInteractProfile() {
    this.isProfileOpen = !this.isProfileOpen;
  }

  handleLogout() {
    this.authService.logout();
    this.isLoggedIn = false;
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
