import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ComponentsModule } from '../../components/components.module';

@Component({
  selector: 'app-meus-cursos',
  imports: [CommonModule, ComponentsModule],
  templateUrl: './meus-cursos.component.html',
  styleUrl: './meus-cursos.component.scss'
})
export class MeusCursosComponent {
  constructor() {}

  avaialbleCourses = [
    {
      title: 'Gestão de obras públicas',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image.png',
      tag: 'Atualizado',
      hours: 30
    },
    {
      title: 'Interpretação de Projetos',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image-7.png',
      tag: 'Atualizado',
      hours: 30
    },
    {
      title: 'Interpretação de Projetos',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image-3.png',
      tag: 'Atualizado',
      hours: 30
    },
    {
      title: 'Orçamento e Planejamento',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image-4.png',
      tag: 'Atualizado',
      hours: 30
    },
    {
      title: 'Gestão de obras públicas',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image-1.png',
      tag: 'Atualizado',
      hours: 30
    },
    {
      title: 'Interpretação de Projetos',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image-5.png',
      tag: 'Atualizado',
      hours: 30
    },
    {
      title: 'Interpretação de Projetos',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image-6.png',
      tag: 'Atualizado',
      hours: 30
    },
    {
      title: 'Orçamento e Planejamento',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/image-2.png',
      tag: 'Atualizado',
      hours: 30
    }
  ];

  availableMaterials = [
    {
      title: 'Modelo de ementa',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-1.png',
      tag: 'Atualizado',
    },
    {
      title: 'Manual de marca',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-2.png',
      tag: 'Atualizado',
    },
    {
      title: 'Modelo de contrato',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-3.png',
      tag: 'Atualizado',
    },
    {
      title: 'Materiais de divulgação',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-4.png',
      tag: 'Atualizado',
    },
    {
      title: 'Manual de vendas',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-5.png',
      tag: 'Atualizado',
    },
    {
      title: 'Modelo de Certificados',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-6.png',
      tag: 'Atualizado',
    },
    {
      title: 'Modelo de Cronograma',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-7.png',
      tag: 'Atualizado',
    },
    {
      title: 'Modelo de Orçamento',
      link: 'https://www.udemy.com/course/angular-avancado/',
      image: 'images/courses/materials/image-8.png',
      tag: 'Atualizado',
    },
  ];
}
