import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CourseCardComponent } from './card/course-card/course-card.component';
import { SmallCardComponent } from './card/small-card/small-card.component';
import { SharedModule } from './shared/shared.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    SmallCardComponent,
    CourseCardComponent
  ],
  exports: [
    SharedModule,
    SmallCardComponent,
    CourseCardComponent
  ]
})
export class ComponentsModule { }
