<main class="meus-cursos-container">

  <div class="meus-cursos-background"></div>

  <div class="meus-cursos-content">
    <div class="meus-cursos-search">
      <input type="text" class="meus-cursos-search-input">
      <button class="meus-cursos-search-button">Buscar</button>
    </div>

    <h2>O que vamos aprender hoje?</h2>
    <p>O curso de Métodos para Aprovação de Alvarás te ensina como navegar pelas exigências e garantir a liberação dos seus projetos com mais agilidade! </p>
    <div class="courses-list-container">
      <app-course-card
        *ngFor="let course of avaialbleCourses"
        [title]="course.title"
        [image]="course.image"
        [tag]="course.tag"
        [hours]="course.hours"
        [link]="course.link"
        linkText="Assistir agora"
      />
    </div>

    <h2>Materiais do franqueado</h2>
    <div class="materials-list-container">
      <app-course-card
        *ngFor="let material of availableMaterials"
        [title]="material.title"
        [image]="material.image"
        [tag]="material.tag"
        [link]="material.link"
        linkText="Baixar material"
      />
    </div>
  </div>
</main>
