import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxMaskDirective } from 'ngx-mask';
import { Profissional } from '../../dto/users/profissional';
import { ApiService } from '../../services/api.service';
import { CepService } from '../../services/cep.service';

@Component({
  selector: 'app-cadastro-profissional',
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatIconModule, NgxMaskDirective],
  templateUrl: './cadastro-franqueado.component.html',
  styleUrl: './cadastro-franqueado.component.scss'
})
export class CadastroFranqueadoComponent {
  constructor(private cepService: CepService, private apiService: ApiService) {}

  private _profissional = new Profissional();
  private _snackbar = inject(MatSnackBar);

  form = new FormGroup({
      name: new FormControl(this._profissional.name, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100)
      ]),
      email: new FormControl(this._profissional.email, [
          Validators.required,
          Validators.email
      ]),
      rg: new FormControl(this._profissional.rg, [
        Validators.required,
        Validators.minLength(5),
      ]),
      cpf: new FormControl(this._profissional.cpf, [
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(14),
        Validators.pattern(/[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/)
      ]),
      technical_number: new FormControl(this._profissional.technical_number, [
        Validators.required,
        Validators.minLength(3),
      ]),
      residence_cep: new FormControl(this._profissional.residence_cep, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/[0-9]{5}\-?[0-9]{3}/)
      ]),
      residence_street: new FormControl(this._profissional.residence_street, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ]),
      residence_neighborhood: new FormControl(this._profissional.residence_neighborhood, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ]),
      residence_complement: new FormControl(this._profissional.residence_complement, [
        Validators.maxLength(100)
      ]),
      residence_number: new FormControl(this._profissional.residence_number, [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/[0-9]+/)
      ]),
      phone_number: new FormControl(this._profissional.phone_number, [
        Validators.required,
        Validators.minLength(11),
        Validators.pattern(/^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[0-9])[0-9]{3}\-?[0-9]{4}$/)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      confirm_password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ])
  }, {
    updateOn: 'blur',
    validators: (control: AbstractControl) => {
      const password = control.get('password');
      const confirm_password = control.get('confirm_password');

      if (password && confirm_password && password.value !== confirm_password.value) {
        confirm_password.setErrors({ mismatch: true });
      }

      return null;
    }
  });

  private _isPasswordHidden = true;

  hide() {
    this._isPasswordHidden = !this._isPasswordHidden;
    return this._isPasswordHidden;
  }

  findCep() {
    this.cepService.buscar(this.form.value.residence_cep!).subscribe((response: any) => {
      const streetControl = this.form.get('residence_street');
      const neighborhoodControl = this.form.get('residence_neighborhood');

      if (streetControl && neighborhoodControl) {
        streetControl.setValue(response.logradouro);
        neighborhoodControl.setValue(response.bairro);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      try {
        const body = {
          name: this.form.value.name || '',
          email: this.form.value.email || '',
          rg: this.form.value.rg || '',
          cpf: this.form.value.cpf || '',
          technical_number: this.form.value.technical_number || '',
          residence_cep: this.form.value.residence_cep || '',
          residence_street: this.form.value.residence_street || '',
          residence_neighborhood: this.form.value.residence_neighborhood || '',
          residence_complement: this.form.value.residence_complement || '',
          residence_number: this.form.value.residence_number || 0,
          phone_number: this.form.value.phone_number || '',
          password: this.form.value.password || ''
        };

        this.apiService.cadastrarProfissional(body).subscribe((response: any) => {
          this._snackbar.open('Profissional cadastrado com sucesso!', 'Fechar', {
            duration: 2000,
          });
        });
      } catch (error) {
        console.error(error);
        this._snackbar.open('Erro ao cadastrar Profissional!', 'Fechar', {
          duration: 2000,
        });
      }
    }
  }
}
