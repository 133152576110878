import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ComponentsModule } from "../../components/components.module";

@Component({
  selector: 'app-home',
  imports: [ComponentsModule, CommonModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  opportunitiesImgPath = './images/home/oportunidades/';
  opportunities = [
    { title: 'Modelo de Negócio Testado', link: '', image: this.opportunitiesImgPath + 'Base.png', imageAlt: '' },
    { title: 'Reconhecimento de Marca', link: '', image: this.opportunitiesImgPath + 'Base-1.png', imageAlt: '' },
    { title: 'Acesso a Tecnologias', link: '', image: this.opportunitiesImgPath + 'Base-2.png', imageAlt: '' },
    { title: 'Reconhecimento de Marca', link: '', image: this.opportunitiesImgPath + 'Base-3.png', imageAlt: '' },
    { title: 'Marketing Estruturado', link: '', image: this.opportunitiesImgPath + 'Base-4.png', imageAlt: '' },
  ];

  coursesImgPath = './images/home/cursos/';
  courses = [
    { title: 'Gestão de obras públicas', link: '', image: this.coursesImgPath + 'Base.png', imageAlt: '' },
    { title: 'Tecnicas de construção civil', link: '', image: this.coursesImgPath + 'Base-1.png', imageAlt: '' },
    { title: 'Orçamento e planejamento', link: '', image: this.coursesImgPath + 'Base-2.png', imageAlt: '' },
    { title: 'Segurança do trabalho', link: '', image: this.coursesImgPath + 'Base-3.png', imageAlt: '' },
    { title: 'Interpretação de projetos', link: '', image: this.coursesImgPath + 'Base-4.png', imageAlt: '' },
  ]
}
