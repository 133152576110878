<footer class="footer-container" [ngClass]="variant === 'normal' ? 'normal' : variant === 'dark' ? 'dark' : 'white'">
  <div class="footer-box">
    <div class="footer-links">
      <div class="footer-links__content">
        <img src="./logos/full-logo.svg" alt="Master Geohub logo">
        <ul *ngFor="let links of footerLinks" class="footer-links__list">
          <li *ngFor="let item of links" class="list-item">
            <h3>{{ item.section }}</h3>
            <a [routerLink]="item.link">{{ item.label }}</a>
          </li>
        </ul>
      </div>
    </div>
    <hr class="line">
    <div class="footer-texts">
      <p class="copyright">© MasterHub - Todos os direitos reservados</p>
      <div class="link-to-terms">
        <p class="use-terms">Termos de Uso</p>
        <p class="privacy-policy">Política de Privacidade</p>
      </div>
    </div>
  </div>
</footer>
