export class Profissional {
  id: string = '';
  name: string = '';
  email: string = '';
  rg: string = '';
  cpf: string = '';
  technical_number: string = '';
  residence_cep: string = '';
  residence_street: string = '';
  residence_neighborhood: string = '';
  residence_complement: string = '';
  residence_number: number = 0;
  phone_number: string = '';
  //necessary_documents: File[]
}
