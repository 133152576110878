<main class="cadastro-container">
  <h1 class="cadastro-container__title">Cadastre-se como profissional</h1>
  <p class="cadastro-container__description">Preencha os dados necessários:</p>

  <div class="cadastro-content">
    <form [formGroup]="this.form" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline" id="name">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Jessica" formControlName="name">
        <mat-error *ngIf="form.get('name')?.hasError('required')">Nome é obrigatório</mat-error>
        <mat-error *ngIf="form.get('name')?.hasError('minlength')">Nome deve ter no mínimo 3 caracteres</mat-error>
        <mat-error *ngIf="form.get('name')?.hasError('maxlength')">Nome deve ter no máximo 50 caracteres</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="rg">
        <mat-label>RG</mat-label>
        <input matInput mask="0.000.000" placeholder="3.333.555" formControlName="rg">
        <mat-error *ngIf="form.get('rg')?.hasError('required')">RG é obrigatório</mat-error>
        <mat-error *ngIf="form.get('rg')?.hasError('minlength')">RG inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="cpf">
        <mat-label>CPF</mat-label>
        <input matInput mask="000.000.000-00" placeholder="000.000.000-10" formControlName="cpf">
        <mat-error *ngIf="form.get('cpf')?.hasError('required')">CPF é obrigatório</mat-error>
        <mat-error *ngIf="form.get('cpf')?.hasError('minlength')">Tamanho inválido</mat-error>
        <mat-error *ngIf="form.get('cpf')?.hasError('maxlength')">Tamanho inválido</mat-error>
        <mat-error *ngIf="form.get('cpf')?.hasError('pattern')">CPF inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="technical-number">
        <mat-label>N° Técnico</mat-label>
        <input matInput placeholder="00000000" formControlName="technical_number">
        <mat-error *ngIf="form.get('technical_number')?.hasError('required')">N° Técnico é obrigatório</mat-error>
        <mat-error *ngIf="form.get('technical_number')?.hasError('minlength')">N° Técnico inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="cep">
        <mat-label>CEP</mat-label>
        <input matInput mask="00000-000" placeholder="22xxx-xxx" (change)="findCep()" (blur)="findCep()" formControlName="residence_cep">
        <mat-error *ngIf="form.get('residence_cep')?.hasError('required')">CEP é obrigatório</mat-error>
        <mat-error *ngIf="form.get('residence_cep')?.hasError('minlength')">CEP inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="address">
        <mat-label>Endereço</mat-label>
        <input matInput placeholder="Av." formControlName="residence_street">
        <mat-error *ngIf="form.get('residence_street')?.hasError('required')">Endereço é obrigatório</mat-error>
        <mat-error *ngIf="form.get('residence_street')?.hasError('minlength')">Endereço deve ter no mínimo 3 caracteres</mat-error>
        <mat-error *ngIf="form.get('residence_street')?.hasError('maxlength')">Endereço deve ter no máximo 100 caracteres</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="complement">
        <mat-label>Complemento</mat-label>
        <input matInput placeholder="Casa" formControlName="residence_complement">
      </mat-form-field>
      <mat-form-field appearance="outline" id="number">
        <mat-label>n°</mat-label>
        <input matInput mask="99999999" formControlName="residence_number">
        <mat-error *ngIf="form.get('residence_number')?.hasError('required')">Número é obrigatório</mat-error>
        <mat-error *ngIf="form.get('residence_number')?.hasError('min')">Número inválido</mat-error>
      </mat-form-field>

      <div id="upload">
        <h5>Media upload</h5>
        <p class="upload__description">Adicione seus documentos aqui e você pode enviar até 5 arquivos no máximo</p>
        <p class="upload__description">Suporta apenas arquivos .jpg, .png, .svg e zip</p>

        <div id="dropzone">
          <mat-icon fontSet="material-icons-outlined">backup</mat-icon>
          <p>Arraste seu(s) arquivo(s) ou <span>Navegador</span></p>
          <p>Arquivos de no máximo 10MB são permitidos</p>
        </div>
      </div>

      <mat-form-field appearance="outline" id="phone">
        <mat-label>Telefone:</mat-label>
        <input matInput prefix="+55 " mask="(00) 00000-0000" placeholder="(xx) xxxxx-xxxx" formControlName="phone_number">
        <mat-error *ngIf="form.get('phone_number')?.hasError('required')">Telefone é obrigatório</mat-error>
        <mat-error *ngIf="form.get('phone_number')?.hasError('minlength')">Telefone inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="email">
        <mat-label for="email">E-mail</mat-label>
        <input email placeholder="master@masterhub.com" matInput formControlName="email">
        <mat-error *ngIf="form.get('email')?.hasError('required')">E-mail é obrigatório</mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('email')">E-mail inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="password">
        <mat-label>Senha:</mat-label>
        <input
          matInput
          [type]="hide() ? 'text' : 'password'"
          formControlName="password"
        >
        <button mat-icon-button matSuffix (click)="hide()">
          <mat-icon matSuffix>{{ hide() ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="form.get('password')?.hasError('required')">Senha é obrigatório</mat-error>
        <mat-error *ngIf="form.get('password')?.hasError('minlength')">Senha deve ter no mínimo 6 caracteres</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" id="confirm-password">
        <mat-label>Confirmar senha:</mat-label>
        <input
          matInput
          [type]="hide() ? 'text' : 'password'"
          formControlName="confirm_password"
        >
        <button
          mat-icon-button
          matSuffix
          (click)="hide()"
          [attr.aria-label]="'Hide password'"
          >
          <mat-icon>{{ hide() ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        <mat-error *ngIf="form.get('confirm_password')?.hasError('required')">Confirmação de senha é obrigatório</mat-error>
        <mat-error *ngIf="form.get('confirm_password')?.hasError('mismatch')">Senhas não conferem</mat-error>
      </mat-form-field>
      <button type="submit" id="button">Cadastrar</button>
    </form>
    <div class="cadastro-content__image">
      <img src="./images/cadastro/franqueado.png" alt="Profissional">
    </div>
  </div>
</main>
