<main>
  <section class="section-container" id="hero-section">
    <h1 class="hero-section__title">Comprometimento com o resultado e a agilidade</h1>
  </section>

  <section class="section-container" id="about-section">
    <h2 class="about-section__title">Sobre a empresa</h2>
    <div class="about-section__content">
      <div>
        <h3 class="content-title">A MasterHub</h3>
        <p class="content-description">
          Entender para atender, esse é o nosso jeito Master de pensar e atuar. É o que nos impulsiona a criar soluções para nossos clientes.<br>
          Somos uma empresa atuante na área de engenharia, existente há 15 anos, voltada para projetos engenharia, arquitetura, infraestrutura, topografia dentre outros diversos serviços, que estão relacionados logo abaixo.
        </p>
        <button class="content-button">
          Saiba mais
        </button>
      </div>
      <img src="./images/home/about.png" alt="Sobre nós">
    </div>
    <div class="about-section__destaques">
      <img src="./images/home/destaques/DESTAQUE-1.svg" alt="Destaque 1">
      <img src="./images/home/destaques/DESTAQUE-2.svg" alt="Destaque 2">
      <img src="./images/home/destaques/DESTAQUE-3.svg" alt="Destaque 3">
      <img src="./images/home/destaques/DESTAQUE-4.svg" alt="Destaque 4">
      <img src="./images/home/destaques/DESTAQUE-5.svg" alt="Destaque 5">
      <img src="./images/home/destaques/DESTAQUE-6.svg" alt="Destaque 6">
    </div>
  </section>

  <section class="section-container" id="products-section">
    <div class="products-section__content" id="opportunities-container">
      <h3 class="products-section__content-title">Oportunidades de Negócios</h3>
      <div class="products-cards__container">
        <ng-container
          *ngFor="let opportunity of opportunities"
        >
          <app-small-card
            [link]="opportunity.link"
            [image]="opportunity.image"
            [imageAlt]="opportunity.imageAlt"
            [title]="opportunity.title"
          ></app-small-card>
        </ng-container>
      </div>
    </div>
    <div class="products-section__content" id="courses-container">
      <h3 class="products-section__content-title">Nossos Cursos</h3>
      <div class="products-cards__container">
        <ng-container
          *ngFor="let course of courses"
        >
          <app-small-card
            [link]="course.link"
            [image]="course.image"
            [imageAlt]="course.imageAlt"
            [title]="course.title"
          ></app-small-card>
        </ng-container>
      </div>
    </div>
  </section>

  <div class="newsletter-container">
    <div class="newsletter-content">
      <h2 class="newsletter-content__title">Assine a nossa Newsletter</h2>
      <p class="newsletter-content__description">
        Receba noticias especiais sobre <br> reposição de estoques e os lançamentos!
      </p>
      <form class="newsletter-form">
        <input
          type="email"
          class="newsletter-input"
          placeholder="Digite seu e-mail"
        >
        <button class="newsletter-button">Inscreva-se</button>
      </form>
    </div>
  </div>
</main>
